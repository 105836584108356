























import { Bind, Debounce } from 'lodash-decorators'
import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MentorEducationModule from '@/store/modules/mentor/education'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
// types
import {
  CourseType,
} from '@/store/types'

@Component({
  components: {
    Tag,
  },
})
export default class ExercisesItem extends Mixins(MasterGroupMixin, NotifyMixin) {
  private get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private get breadcrumbs () {
    const list = [
      { name: 'Домашняя работа', path: '/manager/education/exercises' },
    ]

    if (this.exercise && this.exercise.course.type.value === CourseType.SPECIAL) {
      list.push({
        name: this.exercise.tasks[0] ? this.exercise.title : 'Список мастеров',
        path: `/manager/education/exercises/${this.currentMasterGroupID}/${this.exerciseUUID}/quick-start`,
      })
    } else if (this.exercise && this.exercise.course.type.value !== CourseType.SPECIAL && this.$route.params.masterID) {
      list.push({
        name: this.exercise.title,
        path: `/manager/education/exercises/${this.currentMasterGroupID}/${this.exerciseUUID}/${this.taskUUID}`,
      })
    }

    return list
  }

  private get isSpecialCourse(): boolean | null {
    return this.exercise && this.exercise.course.type.value === CourseType.SPECIAL
  }

  private get title () {
    if (this.exercise && !this.isSpecialCourse) {
      return this.exercise.title
    }
    return 'Проверка задания'
  }

  private get masters() {
    return this.isSpecialCourse ? MentorExercisesModule.mastersSpecialGroup.data : MentorExercisesModule.masters
  }

  private get exercise () {
    return MentorExercisesModule.exercise
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private taskUUID = this.$route.params.taskUUID

  private isLoadingExercise = false

  private created () {
    if (!this.exerciseUUID || !this.exercise || this.exerciseUUID !== this.exercise.uuid)
      this.fetchExercise()

    this.syncMasterGroup('manager.education.exercises')
      .catch(() => {return})
  }

  @Bind
  @Debounce(300)
  private fetchExercise () {
    this.isLoadingExercise = true
    MentorExercisesModule.fetchExercise({
      exerciseUUID: this.exerciseUUID,
      masterGroupID: this.currentMasterGroupID,
    })
      .catch(this.notifyError)
      .finally(() => this.isLoadingExercise = false)
  }

  @Bind
  @Debounce(300)
  private fetchMasters() {
    MentorExercisesModule.fetchMasters({
      masterGroupID: this.currentMasterGroupID,
      taskUUID: this.taskUUID,
    })
      .then(() => {
        if (!this.exerciseUUID || !this.exercise || this.exerciseUUID !== this.exercise.uuid)
          this.fetchExercise()
      })
      // Если запрос на получение мастеров упал, то запрашивать домашку мастера не имеет смысла, т.к. у наставника нет доступа к этой группе мастеров
      // Очищаем текущую группу мастеров, выводим ошибку запроса и запрашиваем доступные группы мастеров для наставника заново
      .catch((error: any) => {
        MentorEducationModule.setCurrentMasterGroupID(-1)
        this.notifyError(error)
        this.$router.replace({ name: 'manager.education.exercises' })
          .then(() => {
            this.$bus.$emit('fetchMasterGroups')
          })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Проверка домашнего задания',
    }
  }

  @Watch('$route.params.exerciseUUID')
  private watchExerciseUUID() {
    this.fetchExercise()
  }
}
